/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICityRow, IDeliveryAddress, IValidationAddress } from '~constants/address';
import { ToastIds } from '~constants/toast-ids';
import { isZipCodeNotServiced, ZipCodeServicedType } from '~constants/zipcode-serviced-type';
import { RootState } from '~store/root-reducer';
import { isNotFoundError } from '~utils/errors';
import { IRequestState } from '~utils/hn-api';
import {
    defaultErrorMessage,
    showApiErrorToast,
    showErrorToast,
    showMessageToast,
} from '~utils/toast-utils';
import { isParentAccount } from '../account-info/selectors';
import { fetchUserLaundryPreferences } from '../laundry-preferences';
import { fetchNextPickupProgressAsync, fetchPickupsAsync } from '../pickups';
import {
    getCitiesRequest,
    getSubscriberZipCodeValidationRequest,
    getUserDeliveryAddressRequest,
    getZipCodeValidationRequest,
    updateDeliveryAddressRequest,
    validateAddressRequest,
} from './api';

export const fetchCities = createAsyncThunk('address/fetchCities', async () => {
    const { data } = await getCitiesRequest();
    return data;
});

export const fetchUserDeliveryAddress = createAsyncThunk(
    'address/fetchDeliveryAddress',
    async () => {
        const { data } = await getUserDeliveryAddressRequest();
        return data;
    },
);

export const submitDeliveryAddress = createAsyncThunk(
    'address/submitDeliveryAddress',
    async (values: IDeliveryAddress, { dispatch, getState }) => {
        try {
            const state = getState() as RootState;
            const isParent = isParentAccount(state);

            const successMessage = isParent
                ? 'Company Address successfully updated!'
                : 'Pickup & Delivery Address successfully updated!';

            const updateAddress = async () => {
                await updateDeliveryAddressRequest(values);
                showMessageToast(successMessage, ToastIds.ADDRESS_UPDATED);
                const result = { ...values } as IDeliveryAddress;
                return result;
            };

            if (!isParent) {
                const { data } = await getSubscriberZipCodeValidationRequest(values.zipCode);

                if (isZipCodeNotServiced(data)) {
                    dispatch(setZipValid(false));
                } else {
                    const updatedAddress = await updateAddress();
                    dispatch(fetchPickupsAsync());
                    dispatch(fetchNextPickupProgressAsync());
                    dispatch(fetchUserLaundryPreferences());
                    return updatedAddress;
                }
            } else {
                return await updateAddress();
            }
        } catch (err) {
            if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.ADDRESS_UPDATED_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.ADDRESS_UPDATED_REJECTED);
            }
            throw err;
        }
    },
);

export const validateAddress = createAsyncThunk(
    'address/validate',
    async (values: IDeliveryAddress) => {
        const res = await validateAddressRequest(values);
        return res.data;
    },
);

export const checkZipCode = createAsyncThunk(
    'address/checkZipCode',
    async (zipCode: string, { dispatch }) => {
        const { data } = await getZipCodeValidationRequest(zipCode);
        if (data === false) {
            // eslint-disable-next-line no-use-before-define
            dispatch(setZipValid(false));
        } else {
            // eslint-disable-next-line no-use-before-define
            dispatch(setZipValid(true));
        }
    },
);

export const fetchZipCodeServicedType = createAsyncThunk(
    'address/checkZipCode',
    async (zipCode: string, { dispatch }) => {
        const { data } = await getSubscriberZipCodeValidationRequest(zipCode);
        dispatch(setZipCodeServicedType(data));
        return data;
    },
);

// TODO: postponed. needed clarification and updates for this module
const initialState = {
    cities: [] as ICityRow[],
    deliveryAddress: {
        address: '',
        aptSuite: '',
        city: '',
        state: '',
        zipCode: '',
        deliveryInstructions: '',
    } as IDeliveryAddress,
    addressValidation: {
        verified: true,
        address: {
            address: '',
            aptSuite: '',
            city: '',
            zipCode: '',
            state: '',
        },
        isLoading: false,
        isSucceed: false,
    } as IValidationAddress,
    zipValid: true,
    zipCodeServicedType: ZipCodeServicedType.ServicedByCurrentSubscriber,
    checkZipCode: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
};

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setZipValid: (state, action: PayloadAction<boolean>) => {
            state.zipValid = action.payload;
        },
        setZipCodeServicedType: (state, action: PayloadAction<ZipCodeServicedType>) => {
            state.zipCodeServicedType = action.payload;
        },
        resetAddressValidation: (state) => {
            state.addressValidation = initialState.addressValidation;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCities.fulfilled, (state, action) => {
            state.cities = action.payload;
        });
        builder.addCase(fetchUserDeliveryAddress.fulfilled, (state, { payload }) => {
            state.deliveryAddress = {
                ...payload,
                city: payload.city || payload.draftCity || '',
                deliveryInstructions: payload.deliveryInstructions || '',
            };
        });
        builder
            .addCase(submitDeliveryAddress.fulfilled, (state, action) => {
                if (action.payload) {
                    state.deliveryAddress = action.payload;
                }
            })
            .addCase(validateAddress.pending, (state) => {
                state.addressValidation.isLoading = true;
                state.addressValidation.isSucceed = false;
            })
            .addCase(validateAddress.fulfilled, (state, action) => {
                if (action.payload) {
                    state.addressValidation.verified = action.payload.verified;
                    if (action.payload.verified) {
                        state.addressValidation.address.address = action.payload.address.Address1;
                        state.addressValidation.address.aptSuite = action.payload.address?.Address2
                            ? action.payload.address?.Address2
                            : '';
                        state.addressValidation.address.city = action.payload.address.City;
                        state.addressValidation.address.zipCode = action.payload.address.Zip5;
                        state.addressValidation.address.state = action.payload.address.State;
                    }
                }
                state.addressValidation.isLoading = false;
                state.addressValidation.isSucceed = true;
            })
            .addCase(validateAddress.rejected, (state) => {
                state.addressValidation.isLoading = true;
                state.addressValidation.isSucceed = true;
            })
            .addCase(checkZipCode.pending, (state) => {
                state.checkZipCode.isLoading = true;
                state.checkZipCode.isSucceed = false;
            })
            .addCase(checkZipCode.fulfilled, (state) => {
                state.checkZipCode.isLoading = false;
                state.checkZipCode.isSucceed = true;
            })
            .addCase(checkZipCode.rejected, (state) => {
                state.checkZipCode.isLoading = false;
                state.checkZipCode.isSucceed = false;
            });
    },
});

export const { setZipValid, setZipCodeServicedType, resetAddressValidation } = addressSlice.actions;

export default addressSlice.reducer;
