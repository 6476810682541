import { addBillingError } from "~constants/billing-info";

export const isTimeoutError = (error: any) => error?.code === 'ECONNABORTED';

export const isNoInternetError = (error: any) => error?.message === 'Network Error';

export const isBadRequestError = (error: any) => error?.response?.status === 400;

export const isNotFoundError = (error: any) => error?.response?.status === 404;

export const isUnauthorizedError = (error: any) => error?.response?.status === 401;

export const isAccessDeniedError = (error: any) => error?.response?.status === 403;

export const isConflictError = (error: any) => error?.response?.status === 409;

export const isTooManyRequests = (error: any) => error?.response?.status === 429;

export const isServiceUnavailableError = (error: any) => error?.response?.status === 503;

export const isInternalServerError = (error: any) => error?.response?.status === 500;

export const getBillingErrorMessage = (error: any) =>
    error.response?.data?.message || addBillingError;

