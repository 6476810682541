import { configureStore } from '@reduxjs/toolkit';
import storeHelper from '~utils/store-helper';
import rootReducer from './root-reducer';

const store = configureStore({
    reducer: rootReducer,
});

// Wrapper for preventing circular dependencies
storeHelper.setupStore(store);

export default store;
